<template>
  <div class="qrcode-content">
    <img src="@/assets/img/qrcode.jpg" alt="客服二维码" />
    <div class="tips">扫一扫/长按二维码，添加怡合达小编-小云</div>
  </div>
</template>
<style lang="less" scoped>
.qrcode-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f5f5f5;
  img {
    width: 80%;
    height: auto;
    border-radius: 10px;
    vertical-align: center;
  }
  .tips {
    line-height: 70px;
    font-size: 14px;
    color: #999;
    text-align: center;
    padding-bottom: 40px;
  }
}
</style>
